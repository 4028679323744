import { createMuiTheme } from '@material-ui/core';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0063B2',
        },
        secondary: {
            main: '#000099',
        },
        background: {
            default: '#fff',
        },
    },
});

export default theme;
