import React from 'react';
import Box from '@material-ui/core/Box';
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SocialMedia from "./socialmedia.js";
import Link from "./link.js";

export default (props) =>
{
    let illustration = "";
    let socialmedia = "";
    let cta1 = "";
    let cta2 = "";
    let height = 620;
    if(props.illustration)
    {
        illustration = <img src={props.illustration} alt="" style={{
            height:    150,
            marginTop: 30
        }}/>;
    }
    else
    {
        illustration = <Box style={{height:30}}/>
        height = 470;
    }
    if(props.socialmedia)
    {
        socialmedia = <Box style={{
            position: "absolute",
            bottom:   70,
            right:    30
        }}>
            social<SocialMedia color="white"/>
        </Box>;
    }
    if(props.cta1txt && props.cta1url && props.cta1url.indexOf("://") < 0)
    {
        cta1 = <Link to={props.cta1url}><Button variant="contained" color="primary" size="large">{props.cta1txt}</Button></Link>;
    }
    if(props.cta1txt && props.cta1url && props.cta1url.indexOf("://") >= 0)
    {
        cta1 = <a href={props.cta1url}><Button variant="contained" color="primary" size="large">{props.cta1txt}</Button></a>;
    }
    if(props.cta2txt && props.cta2url && props.cta2url.indexOf("://") < 0)
    {
        cta2 = <Link to={props.cta2url}><Button variant="outlined" color="primary" size="large" style={{marginRight:20,borderColor:"#fff",color:"#fff"}}>{props.cta2txt}</Button></Link>;
    }
    if(props.cta2txt && props.cta2url && props.cta2url.indexOf("://") >= 0)
    {
        cta2 = <a href={props.cta2url}><Button variant="outlined" color="primary" size="large" style={{marginRight:20,borderColor:"#fff",color:"#fff"}}>{props.cta2txt}</Button></a>;
    }

    return <div className="clearfix" style={{
        height:             height,
        background:    "linear-gradient(rgba(0,0,0,0.1), rgba(156,195,213,1)), url(\"/img/janko-ferlic-sfL_QOnmy00-unsplash.jpg\")",
        //backgroundPosition: props.bgposition+", 0 0",
        backgroundRepeat:   "no-repeat",
        backgroundSize:     "cover",
        backgroundColor:    "#9cc3d5",
        position:           "relative"
    }}>
        <Container maxWidth="lg" style={{
            paddingTop: 64,
            textAlign:  "center",
            color:      "#FFFFFF",
            textShadow: "2px 2px 2px rgba(0, 0, 0, 0.8)"
        }}>
            {illustration}
            <Typography variant="h2" component="h1" style={{
                paddingTop:    20,
                paddingBottom: 20,
                fontWeight:    500
            }}>{props.title}</Typography> <Typography variant="h4" component="p" style={{
            paddingTop:    20,
            paddingBottom: 20,
            fontWeight:    300
        }}>{props.subtitle}</Typography> </Container> <Box style={{
        textAlign:     "center",
        paddingTop:    20,
        paddingBottom: 50
    }}>
        {cta2}{cta1}
        </Box>
        {socialmedia}
    </div>;
};
