import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import Link from "./link.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {ListItemIcon} from "@material-ui/core";

export default (props) =>
{
    const smallMenuItems   = [/*{
        text: "",
        url:  "/"
    }*/];
    const largeMenuItems   = [{
        type: "header",
        text: "Websites",
        url:  "/websites",
        icon: "coffee"
    },{
        type: "link",
        text: "Personal page",
        url:  "/websites/personal",
        icon: "coffee"
    },{
        type: "link",
        text: "Informational site",
        url:  "/websites/informational",
        icon: "coffee"
    },{
        type: "link",
        text: "E-Commerce",
        url:  "/websites/e-commerce",
        icon: "coffee"
    },{
        type: "link",
        text: "Premium",
        url:  "websites/premium",
        icon: "coffee"
    },{
        type: "link",
        text: "Cost calculator",
        url:  "websites/cost-calculator",
        icon: "coffee"
    },{
        type: "header",
        text: "Apps",
        url:  "/apps",
        icon: "coffee"
    },{
        type: "link",
        text: "Web apps",
        url:  "/apps/web",
        icon: "coffee"
    },{
        type: "link",
        text: "Mobile apps",
        url:  "/apps/mobile",
        icon: "coffee"
    },{
        type: "link",
        text: "Custom development",
        url:  "/apps/custom",
        icon: "coffee"
    },{
        type: "link",
        text: "Consulting",
        url:  "/apps/consulting",
        icon: "coffee"
    },{
        type: "header",
        text: "Hosting",
        url:  "/hosting",
        icon: "coffee"
    },{
        type: "link",
        text: "Basic",
        url:  "/hosting/basic",
        icon: "coffee"
    },{
        type: "link",
        text: "Advanced",
        url:  "/hosting/advanced",
        icon: "coffee"
    },{
        type: "link",
        text: "Premium",
        url:  "/hosting/premium",
        icon: "coffee"
    },{
        type: "header",
        text: "Marketing",
        url:  "/marketing",
        icon: "bullhorn"
    },{
        type: "link",
        text: "Search Engine Optimisation (SEO)",
        url:  "/marketing/seo",
        icon: "bullhorn"
    },{
        type: "link",
        text: "Search Engine Marketing (AdWords)",
        url:  "/marketing/sem",
        icon: "bullhorn"
    },{
        type: "link",
        text: "Newsletters",
        url:  "/marketing/newsletters",
        icon: "bullhorn"
    },{
        type: "link",
        text: "Social & Influencer",
        url:  "/marketing/social",
        icon: "bullhorn"
    },{
        type: "link",
        text: "Design",
        url:  "/marketing/design",
        icon: "bullhorn"
    },{
        type: "header",
        text: "Zum Productions",
        url:  "/about",
        icon: "coffee"
    },{
        type: "link",
        text: "About us",
        url:  "/about",
        icon: "coffee"
    },{
        type: "link",
        text: "Portfolio",
        url:  "/about/portfolio",
        icon: "coffee"
    },{
        type: "divider",
        text: "",
        url:  "",
        icon: ""
    },{
        type: "link",
        text: "Get in touch by email",
        url:  "/contact/email",
        icon: "coffee"
    },{
        type: "link",
        text: "Live chat",
        url:  "/contact//chat",
        icon: "coffee"
    },{
        type: "link",
        text: "Book a phone session",
        url:  "/contact//booking",
        icon: "coffee"
    }];
    const [drawer,setDrawer] = React.useState({"open": false});
    const [languageMenu,setLanguageMenu] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const toggleDrawer     = (open) => event =>
    {
        if(event.type === "keydown" && (event.key === "Tab" || event.key === "Shift"))
        {
            return;
        }
        setDrawer({
            drawer,
            "open": open
        });
    };
    const openLanguageMenu = () => event =>
    {
        setLanguageMenu(event.currentTarget);
    };
    const closeLanguageMenu = () => event =>
    {
        setLanguageMenu(null);
    };

    const data = useStaticQuery(graphql`
    {
      allMongodbCmsWebsites {
        edges {
          node {
            mongodb_id
            name
            i18n
            pages {
              menulink {
                  aa ae af ak am an ar as av ay az ba be bg bh bi bm bn bo br bs ca ce ch co cr cs cv cy da de dv dz ee el en eo es et eu fa ff fi fj fo fr fy ga gd gl gn gu gv ha he hi ho hr ht hu hy hz id ig ii ik is it iu ja jv ka kg ki kj kk kl km kn ko kr ks ku kv kw ky lb lg li ln lo lt lu lv mg mh mi mk ml mn mr ms mt my na nb nd ne ng nl nn no nr nv ny oc oj om or os pa pl ps pt qu rm rn ro ru rw sc sd se sg si sk sl sm sn so sq sr ss st su sv sw ta te tg th ti tk tl tn to tr ts tt tw ty ug uk ur uz ve vi wa wo xh yi yo za zh zu
                }
              weight
              status
              slug {
                aa ae af ak am an ar as av ay az ba be bg bh bi bm bn bo br bs ca ce ch co cr cs cv cy da de dv dz ee el en eo es et eu fa ff fi fj fo fr fy ga gd gl gn gu gv ha he hi ho hr ht hu hy hz id ig ii ik is it iu ja jv ka kg ki kj kk kl km kn ko kr ks ku kv kw ky lb lg li ln lo lt lu lv mg mh mi mk ml mn mr ms mt my na nb nd ne ng nl nn no nr nv ny oc oj om or os pa pl ps pt qu rm rn ro ru rw sc sd se sg si sk sl sm sn so sq sr ss st su sv sw ta te tg th ti tk tl tn to tr ts tt tw ty ug uk ur uz ve vi wa wo xh yi yo za zh zu
              }
            }
            status
          }
        }
      }
    }`);

    let languages = [];
    let title = "Sogop";
    let menulinks = [];

    for(let w=0;data && data.allMongodbCmsWebsites && data.allMongodbCmsWebsites.edges && data.allMongodbCmsWebsites.edges[w];w++)
    {
        const node = data.allMongodbCmsWebsites.edges[w].node;
        if(node.mongodb_id === process.env.CMS_SITEID)
        {
            title = node.name;
            languages = node.i18n.map((language) =>
            {
                const languagesById
                          = {
                    "aa": "Afaraf",
                    "ae": "Avestan",
                    "af": "Afrikaans",
                    "ak": "Akan",
                    "am": "አማርኛ",
                    "an": "Aragonés",
                    "ar": "العربية",
                    "as": "অসমীয়া",
                    "av": "Aвар мацӀ",
                    "ay": "Aymar aru",
                    "az": "Azərbaycan dili",
                    "ba": "башҡорт теле",
                    "be": "Беларуская",
                    "bg": "български език",
                    "bh": "भोजपुरी",
                    "bi": "Bislama",
                    "bm": "Bamanankan",
                    "bn": "বাংলা",
                    "bo": "བོད་ཡིག",
                    "br": "Brezhoneg",
                    "bs": "Bosanski",
                    "ca": "Català",
                    "ce": "нохчийн мотт",
                    "ch": "Chamoru",
                    "co": "Corsu",
                    "cr": "ᓀᐦᐃᔭᐍᐏᐣ",
                    "cs": "české",
                    "cv": "чӑваш чӗлхи",
                    "cy": "Cymraeg",
                    "da": "dansk",
                    "de": "Deutsch",
                    "dv": "ދިވެހި",
                    "dz": "རྫོང་ཁ",
                    "ee": "Eʋegbe",
                    "el": "Ελληνικά",
                    "en": "English",
                    "eo": "Esperanto",
                    "es": "Español",
                    "et": "eesti",
                    "eu": "euskara",
                    "fa": "فارسی",
                    "ff": "Fulfulde",
                    "fi": "suomi",
                    "fj": "vosa Vakaviti",
                    "fo": "føroyskt",
                    "fr": "Français",
                    "fy": "Frysk",
                    "ga": "Gaeilge",
                    "gd": "Gàidhlig",
                    "gl": "Galego",
                    "gn": "Avañe'ẽ",
                    "gu": "ગુજરાતી",
                    "gv": "Gaelg",
                    "ha": "هَوُسَ",
                    "he": "עברית",
                    "hi": "हिन्दी, हिंदी",
                    "ho": "Hiri Motu",
                    "hr": "hrvatski",
                    "ht": "Kreyòl ayisyen",
                    "hu": "Magyar",
                    "hy": "Հայերեն",
                    "hz": "Otjiherero",
                    "id": "Indonesian",
                    "ig": "Asụsụ Igbo",
                    "ii": "ꆈꌠ꒿ Nuosuhxop",
                    "ik": "Iñupiaq",
                    "is": "Íslenska",
                    "it": "Italiano",
                    "iu": "ᐃᓄᒃᑎᑐᑦ",
                    "ja": "日本語",
                    "jv": "basa Jawa",
                    "ka": "ქართული",
                    "kg": "KiKongo",
                    "ki": "Gĩkũyũ",
                    "kj": "Kuanyama",
                    "kk": "Қазақ тілі",
                    "kl": "kalaallisut",
                    "km": "ភាសាខ្មែរ",
                    "kn": "ಕನ್ನಡ",
                    "ko": "한국어",
                    "kr": "Kanuri",
                    "ks": "कश्मीरी",
                    "ku": "Kurdî",
                    "kv": "коми кыв",
                    "kw": "Kernewek",
                    "ky": "кыргыз тили",
                    "lb": "Lëtzebuergesch",
                    "lg": "Luganda",
                    "li": "Limburgs",
                    "ln": "Lingála",
                    "lo": "ພາສາລາວ",
                    "lt": "lietuvių kalba",
                    "lu": "Luba-Katanga",
                    "lv": "latviešu valoda",
                    "mg": "Malagasy fiteny",
                    "mh": "Kajin M̧ajeļ",
                    "mi": "te reo Māori",
                    "mk": "македонски јазик",
                    "ml": "മലയാളം",
                    "mn": "монгол",
                    "mr": "मराठी",
                    "ms": "bahasa Melayu",
                    "mt": "Malti",
                    "my": "Burmese",
                    "na": "Ekakairũ Naoero",
                    "nb": "Norsk bokmål",
                    "nd": "isiNdebele",
                    "ne": "नेपाली",
                    "ng": "Owambo",
                    "nl": "Nederlands",
                    "nn": "Norsk nynorsk",
                    "no": "Norsk",
                    "nr": "isiNdebele",
                    "nv": "Diné bizaad",
                    "ny": "chiCheŵa",
                    "oc": "Occitan",
                    "oj": "ᐊᓂᔑᓈᐯᒧᐎᓐ",
                    "om": "Afaan Oromoo",
                    "or": "ଓଡ଼ିଆ",
                    "os": "ирон æвзаг",
                    "pa": "ਪੰਜਾਬੀ",
                    "pl": "polski",
                    "ps": "پښتو",
                    "pt": "Português",
                    "qu": "Runa Simi",
                    "rm": "rumantsch grischun",
                    "rn": "Ikirundi",
                    "ro": "Română",
                    "ru": "русский",
                    "rw": "Ikinyarwanda",
                    "sc": "sardu",
                    "sd": "सिन्धी",
                    "se": "Davvisámegiella",
                    "sg": "yângâ tî sängö",
                    "si": "සිංහල",
                    "sk": "slovenčina",
                    "sl": "slovenščina",
                    "sm": "gagana fa'a Samoa",
                    "sn": "chiShona",
                    "so": "Soomaaliga",
                    "sq": "Shqip",
                    "sr": "српски језик",
                    "ss": "SiSwati",
                    "st": "Sesotho",
                    "su": "Basa Sunda",
                    "sv": "Svenska",
                    "sw": "Kiswahili",
                    "ta": "தமிழ்",
                    "te": "తెలుగు",
                    "tg": "тоҷикӣ",
                    "th": "ไทย",
                    "ti": "ትግርኛ",
                    "tk": "Türkmen",
                    "tl": "Wikang Tagalog",
                    "tn": "Setswana",
                    "to": "Tonga",
                    "tr": "Türkçe",
                    "ts": "Xitsonga",
                    "tt": "татарча",
                    "tw": "Twi",
                    "ty": "Reo Tahiti",
                    "ug": "Uyƣurqə",
                    "uk": "українська",
                    "ur": "اردو",
                    "uz": "O'zbek",
                    "ve": "Tshivenḓa",
                    "vi": "Việt",
                    "wa": "Walon",
                    "wo": "Wollof",
                    "xh": "IsiXhosa",
                    "yi": "ייִדיש",
                    "yo": "Yorùbá",
                    "za": "Saɯ cueŋƅ",
                    "zh": "中文",
                    "zu": "IsiZulu"
                };

                return{
                    "name": languagesById[language],
                    "id":   language
                };
            });
            for(let l = 0; node.i18n[l]; l++)
            {
                if(node.i18n[l] === props.i18n)
                {
                    for(let ml = 0; node.pages[ml]; ml++)
                    {
                        if(node.pages[ml].status && node.pages[ml].status === "published"
                           && node.pages[ml].menulink
                           && node.pages[ml].menulink[node.i18n[l]])
                        {
                            menulinks.push({
                                "title": node.pages[ml].menulink[node.i18n[l]],
                                "url":   "/" + node.i18n[l] + "/" + node.pages[ml].slug[node.i18n[l]]
                            });
                        }
                    }
                }
            }
        }
    }

    return (<header>
                <AppBar position="fixed" style={{background: "#0063B2"}}>
                    <Container maxWidth="lg"> <Toolbar>
                        <IconButton edge="start" style={{marginRight: 30}} color="inherit" aria-label="Menu" onClick={toggleDrawer(true)}>
                            <FontAwesomeIcon icon="bars"/> </IconButton> <Typography variant="h6" component="span" style={{flexGrow: 1}}>
                        <span style={{whiteSpace: "nowrap"}}><Link to={"/"+props.i18n}>{title}</Link></span>
                    </Typography> <Box display={{
                        xs: "none",
                        md: "block"
                    }}>
                        {smallMenuItems && smallMenuItems.map((item) =>
                        {
                            if(typeof window !== 'undefined' && window.location && window.location.pathname && window.location.pathname.indexOf(item.url) >= 0)
                            {
                                return (<Link to={"/"+props.i18n+item.url}><Button color="inherit" style={{
                                    color:          "inherit",
                                    textDecoration: "none",
                                    backgroundColor: "rgba(0, 0, 0, 0.18)"
                                }}>{item.text}</Button></Link>)
                            }
                            else
                            {
                                return (<Link to={"/"+props.i18n+item.url}><Button color="inherit" style={{
                                    color:          "inherit",
                                    textDecoration: "none"
                                }}>{item.text}</Button></Link>)
                            }
                        })}
                        {menulinks && menulinks.map((page) =>
                        {
                            if(typeof window !== 'undefined' && window.location && window.location.pathname && window.location.pathname.indexOf(page.url) >= 0)
                            {
                                return (<Link to={page.url}><Button color="inherit" style={{
                                    color:          "inherit",
                                    textDecoration: "none",
                                    backgroundColor: "rgba(0, 0, 0, 0.18)"
                                }}>{page.title}</Button></Link>);
                            }
                            else
                            {
                                return (<Link to={page.url}><Button color="inherit" style={{
                                    color:          "inherit",
                                    textDecoration: "none"
                                }}>{page.title}</Button></Link>);
                            }
                        })}
                    </Box>
                        {
                            languages && <Box>
                                <IconButton edge="end" color="inherit" aria-controls="simple-menu" aria-haspopup="true" aria-label="Menu" onClick={openLanguageMenu()}>
                                    <FontAwesomeIcon icon="globe"/> </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={languageMenu}
                                    keepMounted
                                    open={Boolean(languageMenu)}
                                    onClose={closeLanguageMenu()}
                                >
                                    {languages.map((language) =>
                                    {
                                        return (<MenuItem component={Link} to={"/"+language.id} onClick={closeLanguageMenu()}>{language.name}</MenuItem>)
                                    })}
                                </Menu>
                            </Box>
                        }
                    </Toolbar> </Container> </AppBar> <Drawer open={drawer.open} onClose={toggleDrawer(false)}>
                <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <List style={{
                        width:      "100%",
                        maxWidth:   360,
                        position:   "relative",
                        maxHeight:  "100%",
                        paddingTop: 0
                    }}>
                        <Link to={"/"+props.i18n} style={{
                            color:          "inherit",
                            textDecoration: "none"
                        }}>
                            <ListSubheader style={{
                                textAlign:  "center",
                                color:      "#FFFFFF",
                                textShadow: "1px 1px 1px rgba(0, 0, 0, 0.8)",
                                background: "#0063B2"
                            }}>{title}</ListSubheader>
                        </Link>

                        {menulinks && menulinks.map((page) =>
                        {
                            return (<Link to={page.url} style={{
                                color:          "inherit",
                                textDecoration: "none"
                            }}> <ListItem button key={page.title}>
                                <ListItemText primary={page.title}/> </ListItem> </Link>);

                        })} </List>
                </div>
            </Drawer>
            </header>);
};
